<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <div class="col-12">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input :validate-error="errors[0]" v-model="form.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('district_code')">
                            <b-form-input :validate-error="errors[0]" v-model="form.district_code">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="yoksis_code" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('city_id')">
                            <city-selectbox v-model="form.city_id"></city-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="updateForm" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save").toLocaleUpperCase('tr-TR') }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import DistrictOutlineService from "@/services/DistrictOutlineService";

    export default {
        components: {
            ValidationProvider, ValidationObserver,
            CitySelectbox
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    city_id: null,
                    district_code: null,
                    name: null,
                }
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                DistrictOutlineService.get(id)
                                      .then((response) => {
                                          this.form = response.data.data;
                                          this.formLoading = false;
                                      })
                                      .catch((error) => {
                                          if (error.data.message) {
                                              this.$toast.error(this.$t("api." + error.data.message));
                                          }
                                      })
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    DistrictOutlineService.update(this.formId, this.form)
                                          .then((response) => {
                                              this.$toast.success(this.$t("api." + response.data.message));
                                              this.$emit("updateFormSuccess")
                                          })
                                          .catch((error) => {
                                              this.showErrors(error, this.$refs.formModalValidate)
                                          }).finally(() => {
                        this.formLoading = false;
                    })
                }
            }
        }
    }
</script>
