<template>
    <div>
        <ValidationObserver ref="formModalValidate">
                <b-row>
                    <div class="col-12">
                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('name')">
                                <b-form-input :validate-error="errors[0]" v-model="form.name">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('district_code')">
                                <b-form-input :validate-error="errors[0]" v-model="form.district_code">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="yoksis_code" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('city_id')">
                                <city-selectbox v-model="form.city_id"></city-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-row>
            </ValidationObserver>
            <div class="col-12 mt-3 d-flex">
                <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2">
                    {{ $t("save").toLocaleUpperCase('tr-TR') }}
                </b-button>
            </div>
    </div>
</template>

<script>
    //Component
    import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import DistrictOutlineService from "@/services/DistrictOutlineService";

    export default {
        components: {
            ValidationProvider, ValidationObserver,
            CitySelectbox
        },
        data() {
            return {
                formLoading: false,
                form: {
                    city_id: null,
                    district_code: null,
                    name: null,
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    DistrictOutlineService.store(this.form)
                                   .then((response) => {
                                       this.$toast.success(this.$t("api." + response.data.message));
                                       this.$emit("createFormSuccess")
                                   })
                                   .catch((error) => {
                                       this.showErrors(error, this.$refs.formModalValidate)
                                   });
                }
            }
        }
    }
</script>
